import { Link, useNavigate } from "react-router-dom";
import "./home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay } from "swiper/core";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import Header from "../components/Header";

function Home() {
    const url = `${process.env.REACT_APP_API_URL}/auth/getUser`;
    SwiperCore.use([Autoplay]);
    const swiperParams = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        }
    };
    const swiperGame5 = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        slidesPerView: 4,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const [profile, setProfile] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        axios.get(url, {}).then((res) => {
            setProfile(res.data.data);
            localStorage.setItem("profile", JSON.stringify(res.data.data));
        });
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
    }, []);

    const [loading, setLoading] = useState(false);
    function isMobileDevice() {
        if (window.innerWidth < 800) {
            return true;
        }
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API_URL}/casino/create`, {});
    }, [profile]);

    if (url.length != 37) return;
    return (
        <>
            <Header profile={profile} />
            {loading == true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <div className="bg-black">
                {swiperParams && notify && (
                    <div className="box-slide" style={{ overflow: "hidden" }}>
                        <Swiper {...swiperParams}>
                            {notify.map((item, index) => (
                                <>
                                    {index > 1 && item.title !== "marquee" && item.title !== "popup" && item.isShow === true ? (
                                        <SwiperSlide>
                                            <img alt={item.title.replace("}", "").replace("{", "")} src={item.image} />
                                        </SwiperSlide>
                                    ) : null}
                                </>
                            ))}
                        </Swiper>
                    </div>
                )}

                {notify ? (
                    <>
                        {notify[0].isShow === true && notify[0].title === "marquee" ? (
                            <div className="header-notify">
                                <div className="container">
                                    <div className="header-noti d-flex">
                                        <div className="notify-title">
                                            <i className="icon_news"></i>
                                        </div>
                                        <marquee>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: notify[0].content
                                                }}
                                            />
                                        </marquee>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </>
                ) : null}
                <div className="container">
                    <div className="super-title">
                        <div className="super-title-left">
                            <img src="/images/left.png.avif" />
                        </div>
                        <div className="super-title-center">
                            <div className="super-title-big">GAME CỰC CHẤT</div>
                            <div className="super-title-small">THAM GIA TRANH TÀI</div>
                        </div>
                        <div className="super-title-right">
                            <img src="/images/left.png.avif" style={{ transform: "rotateY(180deg)" }} />
                        </div>
                    </div>
                    <div className="grid-3">
                        <div className="super-big-item pointer" onClick={() => navigate("/casino")}>
                            <div className="item_cont">
                                <img className="app-image item_bj" src="/images/big_bj_39636407564188.png.avif" loading="lazy" />
                                <div className="item_but">CASINO</div>
                            </div>
                            <div className="figure_cont">
                                <img className="app-image figure" src="/images/figure_39636407564188.png.avif" loading="lazy" />
                            </div>
                        </div>
                        <div className="super-big-item pointer" onClick={() => navigate("/sports")}>
                            <div className="item_cont">
                                <img className="app-image item_bj" src="/images/big_bj_362857114342923387.png.avif" loading="lazy" />
                                <div className="item_but">THỂ THAO</div>
                            </div>
                            <div className="figure_cont">
                                <img className="app-image figure" src="/images/figure_362857114342923387.png.avif" loading="lazy" />
                            </div>
                        </div>
                        <div className="super-big-item pointer" onClick={() => navigate("/lottery")}>
                            <div className="item_cont">
                                <img className="app-image item_bj" src="/images/big_bj_8840968486572375835.png.avif" loading="lazy" />
                                <div className="item_but">LÔ ĐỀ</div>
                            </div>
                            <div className="figure_cont">
                                <img className="app-image figure" src="/images/figure_8840968486572375835.png.avif" loading="lazy" />
                            </div>
                        </div>
                    </div>
                    <div className="grid-5">
                        <div className="super-small-item pointer" onClick={() => navigate("/sicbo")}>
                            <div className="item_cont">
                                <img className="app-image item_bj" src="/images/small_bj_41465988833800.png.avif" loading="lazy" />
                                <div className="item_but">TÀI XỈU</div>
                            </div>
                            <img className="app-image figure" src="/images/taixiu.png" loading="lazy" />
                        </div>
                        <div className="super-small-item pointer" onClick={() => navigate("/pvp")}>
                            <div className="item_cont">
                                <img className="app-image item_bj" src="/images/small_bj_16564048344271.png.avif" loading="lazy" />
                                <div className="item_but">GAME BÀI</div>
                            </div>
                            <img className="app-image figure" src="/images/subclass41465988833800.png.avif" loading="lazy" />
                        </div>
                        <div className="super-small-item pointer" onClick={() => navigate("/slots")}>
                            <div className="item_cont">
                                <img className="app-image item_bj" src="/images/small_bj_6861705028422769039.png.avif" loading="lazy" />
                                <div className="item_but">NỔ HŨ</div>
                            </div>
                            <img className="app-image figure" src="/images/subclass6861705028422769039.png.avif" loading="lazy" />
                        </div>
                        <div className="super-small-item pointer" onClick={() => navigate("/fishing")}>
                            <div className="item_cont">
                                <img className="app-image item_bj" src="/images/small_bj_44196810703047.png.avif" loading="lazy" />
                                <div className="item_but">BẮN CÁ</div>
                            </div>
                            <img className="app-image figure" src="/images/subclass44196810703047.png.avif" loading="lazy" />
                        </div>
                        <div className="super-small-item pointer" onClick={() => navigate("/sicbo")}>
                            <div className="item_cont">
                                <img className="app-image item_bj" src="/images/small_bj_16754997519210.png.avif" loading="lazy" />
                                <div className="item_but">XÓC ĐĨA</div>
                            </div>
                            <img className="app-image figure" src="/images/xocdia.png" loading="lazy" />
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="super-title">
                        <div className="super-title-left">
                            <img src="/images/left.png.avif" />
                        </div>
                        <div className="super-title-center" style={{ width: "35%" }}>
                            <div className="super-title-big">DỊCH VỤ CHẤT LƯỢNG</div>
                            <div className="super-title-small">AN TOÀN ỔN ĐỊNH</div>
                        </div>
                        <div className="super-title-right">
                            <img src="/images/left.png.avif" style={{ transform: "rotateY(180deg)" }} />
                        </div>
                    </div>
                    <div className="textIs textIn">
                        <div className="superior" style={{ marginBottom: "20px" }}>
                            <div className="flex jc-s">
                                <div className="meter_item ai-c flex fdr-c">
                                    <div className="meter">
                                        <div className="contain pos-a">
                                            <svg width="138px" height="138px">
                                                <defs>
                                                    <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                                                        <stop offset="0%" stop-color="#25abe0"></stop>
                                                        <stop offset="100%" stop-color="#61CEFF"></stop>
                                                    </linearGradient>
                                                </defs>
                                                <path
                                                    id="myPath"
                                                    stroke-dasharray="320"
                                                    stroke-dashoffset="5"
                                                    d="M 42 102  
          A 45 45 0 1 1 114 68"
                                                    stroke="url(#linear)"
                                                    stroke-width="10"
                                                    stroke-linecap="round"
                                                    fill="none"></path>
                                            </svg>
                                        </div>
                                        <div className="view fdr-c flex-middle">
                                            <div className="num">60</div>
                                            <div className="unit">s</div>
                                        </div>
                                        <div className="flex-middle fdr-c describe">
                                            <p className="title">TRUNG BÌNH</p>
                                            <p className="desc">Thời Gian Nạp Tiền</p>
                                        </div>
                                    </div>
                                    <div className="home_desc fdr-c pointer">
                                        <div className="cg_icon">
                                            <img className="app-image" src="/images/i_assure1.png.avif" loading="lazy" />
                                        </div>
                                        <p className="title">AN TOÀN HƠN</p>
                                        <div className="desc">
                                            Độc quyền phát triển, sử dụng công nghệ mã hóa 128-bit và hệ thống quản lý bảo mật nghiêm ngặt, tiền của khách hàng được bảo vệ toàn vẹn
                                            nhất, giúp bạn tận hưởng trọn vẹn hoạt động giải trí, đặt cược vào các trận đấu và không cần lo lắng!
                                        </div>
                                    </div>
                                </div>
                                <div className="meter_item ai-c flex fdr-c">
                                    <div className="meter">
                                        <div className="contain pos-a">
                                            <svg width="138px" height="138px">
                                                <defs>
                                                    <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                                                        <stop offset="0%" stop-color="#25abe0"></stop>
                                                        <stop offset="100%" stop-color="#61CEFF"></stop>
                                                    </linearGradient>
                                                </defs>
                                                <path
                                                    id="myPath"
                                                    stroke-dasharray="320"
                                                    stroke-dashoffset="5"
                                                    d="M 42 102  
          A 45 45 0 1 1 114 68"
                                                    stroke="url(#linear)"
                                                    stroke-width="10"
                                                    stroke-linecap="round"
                                                    fill="none"></path>
                                            </svg>
                                        </div>
                                        <div className="view fdr-c flex-middle">
                                            <div className="num">80</div>
                                            <div className="unit">Nhà</div>
                                        </div>
                                        <div className="flex-middle fdr-c describe">
                                            <p className="title">HỢP TÁC</p>
                                            <p className="desc">Hỗ Trợ Trang Web</p>
                                        </div>
                                    </div>
                                    <div className="home_desc fdr-c pointer">
                                        <div className="cg_icon">
                                            <img className="app-image" src="/images/i_assure2.png.avif" loading="lazy" />
                                        </div>
                                        <p className="title">CHUYÊN NGHIỆP HƠN</p>
                                        <div className="desc">
                                            Cung cấp cho bạn gần một nghìn môn thể thao thú vị, sự kiện thể thao điện tử mỗi ngày, trải nghiệm thú vị tột đỉnh về cách chơi và nhiều
                                            hình thức giải trí khác như live casino, game bài, lô đề, nổ hũ,… tùy ý thích của bạn chọn.
                                        </div>
                                    </div>
                                </div>
                                <div className="meter_item ai-c flex fdr-c">
                                    <div className="meter">
                                        <div className="contain pos-a">
                                            <svg width="138px" height="138px">
                                                <defs>
                                                    <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                                                        <stop offset="0%" stop-color="#25abe0"></stop>
                                                        <stop offset="100%" stop-color="#61CEFF"></stop>
                                                    </linearGradient>
                                                </defs>
                                                <path
                                                    id="myPath"
                                                    stroke-dasharray="320"
                                                    stroke-dashoffset="5"
                                                    d="M 42 102  
          A 45 45 0 1 1 114 68"
                                                    stroke="url(#linear)"
                                                    stroke-width="10"
                                                    stroke-linecap="round"
                                                    fill="none"></path>
                                            </svg>
                                        </div>
                                        <div className="view fdr-c flex-middle">
                                            <div className="num">90</div>
                                            <div className="unit">s</div>
                                        </div>
                                        <div className="flex-middle fdr-c describe">
                                            <p className="title">TRUNG BÌNH</p>
                                            <p className="desc">Thời Gian Rút Tiền</p>
                                        </div>
                                    </div>
                                    <div className="home_desc fdr-c pointer">
                                        <div className="cg_icon">
                                            <img className="app-image" src="/images/i_assure3.png.avif" loading="lazy" />
                                        </div>
                                        <p className="title">TỐC ĐỘ HƠN</p>
                                        <div className="desc">
                                            Hệ thống xử lý tài chính được nghiên cứu và phát triển độc lập bằng công nghệ mới nhất, thật sự đạt được hiệu quả kỹ thuật, nạp, rút,
                                            chuyển. Công nghệ tối ưu hóa mạng độc quyền.
                                        </div>
                                    </div>
                                </div>
                                <div className="meter_item ai-c flex fdr-c">
                                    <div className="meter">
                                        <div className="contain pos-a">
                                            <svg width="138px" height="138px">
                                                <defs>
                                                    <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
                                                        <stop offset="0%" stop-color="#25abe0"></stop>
                                                        <stop offset="100%" stop-color="#61CEFF"></stop>
                                                    </linearGradient>
                                                </defs>
                                                <path
                                                    id="myPath"
                                                    stroke-dasharray="320"
                                                    stroke-dashoffset="5"
                                                    d="M 42 102  
          A 45 45 0 1 1 114 68"
                                                    stroke="url(#linear)"
                                                    stroke-width="10"
                                                    stroke-linecap="round"
                                                    fill="none"></path>
                                            </svg>
                                        </div>
                                        <div className="view fdr-c flex-middle">
                                            <div className="num">19</div>
                                            <div className="unit">Nhà</div>
                                        </div>
                                        <div className="flex-middle fdr-c describe">
                                            <p className="title">HỢP TÁC</p>
                                            <p className="desc">Trang Web Trò Chơi</p>
                                        </div>
                                    </div>
                                    <div className="home_desc fdr-c pointer">
                                        <div className="cg_icon">
                                            <img className="app-image" src="/images/i_assure4.png.avif" loading="lazy" />
                                        </div>
                                        <p className="title">THUẬN TIỆN HƠN</p>
                                        <div className="desc">
                                            Ứng dụng gốc tự nghiên cứu phát triển Web, H5, IOS, Android, giúp bạn tùy thích đặt cược mọi lúc mọi nơi! Cung cấp dịch vụ chăm sóc
                                            khách hàng 24/7, tận tình chăm sóc.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className="container">
                    <div className="section-title" onClick={() => navigate("/lottery")}>
                        Live Casino
                    </div>
                    <div className="menu-box-game" style={{ marginTop: "0" }}>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/DG.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "27", game_code: "DG0013" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/AG.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "4", game_code: "A00070" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/WM.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "118", game_code: "WM0001" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/aeSexy.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "112", game_code: "SEX001" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="section-title" onClick={() => navigate("/sports")}>
                        Thể Thao
                    </div>
                    <div className="menu-box-game" style={{ marginTop: "0" }}>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/UG.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "142", game_code: "FB0001" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/SABA.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "174", game_code: "SB0001" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/CMD.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "104", game_code: "CMD001" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/SBO.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "174", game_code: "SB0001" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                    </div>
                </div>*/}
            </div>

        </>
    );
}
export default Home;
