import "./footer.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import InfoIcon from "@mui/icons-material/Info";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

function Footer() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const navigate = useNavigate();
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const [popup, setPopup] = useState(false);
    useEffect(() => {
        if (localStorage.getItem("setting") !== null && localStorage.getItem("setting") !== "undefined") {
            setSetting(JSON.parse(localStorage.getItem("setting")));
        } else {
            axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
                setSetting(res.data.data[0]);
            });
        }
    }, []);
    const [isPlaying, setIsPlaying] = useState(false);
    const [audio, setAudio] = useState(null);
    useEffect(() => {
        if (localStorage.getItem("mp3")?.length > 5) {
            setAudio(new Audio(localStorage.getItem("mp3")));
        } else {
            axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
                setAudio(new Audio(res.data.data[0]?.mp3));
                localStorage.setItem("mp3", res.data.data[0]?.mp3);
            });
        }
        return () => {
            if (audio) {
                audio.pause();
            }
        };
    }, []);
    const toggleAudio = () => {
        if (audio) {
            if (audio.paused) {
                audio.play();
            } else {
                audio.muted = !audio.muted;
            }
            setIsPlaying(!isPlaying);
        }
    };
    return (
        <>
            <footer>
                <div className="container">
                    <div style={{ padding: "10px 0", borderBottom: "1px solid #4d595e" }}>
                        <img src={require("../../image/products.png")} />
                    </div>
                    <div class="describes text pointer">
                        HP88 được cấp 4 giấy phép hợp pháp đạt chuẩn quốc tế được cấp bởi Chính phủ Philippines (PAGCOR), Chính phủ Malta (MGA), Quẩn đảo Virgin Vương quốc
                        Anh (BVI) &amp; HIệp Hội Nhà Cái Anh (GAMBLING COMMISSION), là một nhà cái cá cược được Hiệp Hội Cá Cược Quốc Tế công nhận. Trước khi đăng ký tham gia đặt
                        cược, vui lòng đảm bảo bạn đã đủ 18 tuổi.
                    </div>
                    <div className="footer-top">
                        <div>
                            <img src="/images/certificate1.png.avif" />
                            <div class="text">Giấy phép game cá cược Philippines (PAGCOR)</div>
                        </div>
                        <div>
                            <img src="/images/certificate2.png.avif" />
                            <div class="text">Giấy phép cá cược Malta (MGA)</div>
                        </div>
                        <div>
                            <img src="/images/certificate3.png.avif" />
                            <div class="text">Giấy phép Quần đảo Virgin Anh (BVI)</div>
                        </div>
                        <div>
                            <img src="/images/certificate4.png.avif" />
                            <div class="text">Uỷ ban GC Supervisory của Anh</div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="footer_help" style={{ padding: "20px 0" }}>
                            <ul>
                                <li>
                                    <Link>Giúp Đỡ Người Mới</Link>
                                </li>
                                <li>
                                    <Link>Trách Nhiệm Cá Cược</Link>
                                </li>
                                <li>
                                    <Link>Quy Định Điều Khoản</Link>
                                </li>
                                <li>
                                    <Link>Chính Sách Riêng Tư</Link>
                                </li>
                                <li>
                                    <a href={setting?.down_link} target="_blank">
                                        Tải Ứng Dụng
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="copyright">Copyright © 2024 All Rights Reserved</div>
                    </div>
                </div>
            </footer>
            <div className="br_right_bar">
                <div className="br_bar_mnav active">
                    <div className="br_sbgimg liveChat" onClick={() => window.open(`${setting?.cskh}`, "_blank")}>
                        <span className="br_rightbar_icon">
                            <img src="/images/i_service.png.avif" style={{ width: "32px", height: "32px", margin: "10px 0" }} />
                        </span>
                        <p>Hỗ trợ trực tuyến</p>
                    </div>
                    <a className="br_sbgimg appDownload" href={setting?.down_link} target="_blank">
                        <span className="br_rightbar_icon">
                            <img src="/images/i_app.png.avif" style={{ width: "28px", height: "28px", margin: "12px 0" }} />
                        </span>
                        <p>Tải App</p>
                    </a>
                    <div className="br_sbgimg promotion" onClick={() => navigate("/promotion")}>
                        <span className="br_rightbar_icon">
                            <svg aria-hidden="true" className="svg-icon">
                                <use xlinkHref="#icon-promotion"></use>
                            </svg>
                        </span>
                        <p>Khuyến mãi</p>
                    </div>
                </div>
            </div>
            {/*<div className="btn-info-mini" onClick={() => setPopup(true)}>
                <InfoIcon />
            </div>
            {popup === true && (
                <div className="popup-backdrop">
                    <div className="popup-main">
                        <div className="popup-header">Chú ý</div>
                        <div className="popup-content">
                            <p>
                                Để đảm bảo web được hoạt động lâu dài cũng như bắt buộc duy trì các hoạt động đóng thuế cho doanh nghiệp, đối với các quý khách rút điểm quy đổi
                                trên 600.000.000 VND. Khi rút điểm cần thực hiện đóng phí duy trì theo hạn mức rút điểm như sau:
                            </p>
                            <ul>
                                <li>Hạn mức rút tài khoản từ 600 triệu - 1.000.000.000 VND tương ứng với 20% phí.</li>
                                <li>Hạn mức rút tài khoản trên 1.000.000.000 VND tương ứng với 30% phí.</li>
                            </ul>
                        </div>
                        <button onClick={() => setPopup(false)} className="popup-close">
                            Đóng
                        </button>
                    </div>
                </div>
            )}*/}
        </>
    );
}
export default Footer;
